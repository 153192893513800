/**
 * Custom Site Functionality
 * Author - Edge Marketing
 *
 * Version - 1.2
 */

$(document).ready(function(){
    new Swiper('.swiper-container', {
        autoplay: {
            delay: 5000,
        },
        fadeEffect: {
            crossFade: true
        },
        speed: 750,
        spaceBetween: 100,
        grabCursor: true,
        loop: true,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    $('.clear-height').css("height","auto");

    $('[data-fancybox="gallery"]').fancybox({
        thumbs : {
            autoStart : true
        }
    });

    $(".open-gallery").on("click", function(){
        $(".gallery").eq(0).trigger("click");
        return false;
    });

    $('.scroll-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $('.gallery-hover').each(function() {
        var $this = $(this);
        var $caption = $this.find('.hover-caption');
        $this.hover(function() {
            $caption.stop().fadeIn(200);
        }, function() {
            $caption.stop().fadeOut(200);
        });
    });

    $("form").submit(function() {
        swal({
            title: "Processing...",
            icon: "warning",
            buttons: {
                confirm: {
                    closeModal: false
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    });

    $('[data-toggle="tooltip"]').tooltip();

    var $stickyTop = $('.filter-sticky-top');
    $('.testimonial').waypoint(function(direction) {
        if (direction == 'down') {
            $stickyTop.removeClass('filter-sticky-top');
        } else {
            $stickyTop.addClass('filter-sticky-top');
        }
    }, {
        offset: '200px'
    })
});
